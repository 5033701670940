/* eslint-disable */
import { Routes, Route } from "react-router-dom";
import './App.scss';
import Layout from './Layout';
import ProjectList from './features/project-list/ProjectList';
import MapOne from "./app-views/MapOne";
import EsriMapView from "./app-views/EsriMapView";
import PanoImageryOne from "./app-views/PanoImageryOne";
import PointCloudTwo from './app-views/PointcloudTwo';
import PseudoLogin from "./app-views/PseudoLogin";

const App = ({msalInstance}) => {

  return (
    <Routes>
      <Route path="/" element={<Layout instance={msalInstance}/>}>
        <Route index element={<ProjectList />} />
        {/* <Route path="login" element={<PseudoLogin />} /> */}
        <Route path="login" element={<ProjectList />} />
        <Route path="map-one" element={<MapOne />} />
        <Route path="esri-map" element={<EsriMapView />} />
        <Route path="pano-imagery-one" element={<PanoImageryOne />} />
        <Route path="pointcloud-two" element={<PointCloudTwo />} />
      </Route>
    </Routes>
  )
}

export default App;
