import { Box, Button } from "@mui/material";
import { getAssetTypeColor } from "../Enums/AssetTypeColor";
import { StoreContext } from "../Contexts";
import { useContext, useEffect, useState } from "react";
import { Circle } from "@mui/icons-material";

const PointcloudLegend = () => {
    const appContext = useContext(StoreContext);
    const [activePole] = appContext.activePole;
    const [relatedAssets] = appContext.relatedAssets;
    const [measurementMap] = appContext.measurementMap;
    const [legendItems, setLegendItems] = useState([]);
    const [hideLegend, setHideLegend] = useState(true);
    const [colorCode, setColorCode] = useState(true);
    const assetInList = (asset, list) => list.some(
        (ele) => ele.asset_name === asset.asset_name && ele.asset_type_name === asset.asset_type_name
    );

    useEffect(() => {
        if (!colorCode) {
            setLegendItems([{ asset_name: "Assets", asset_type_name: "All" }]);
            return;
        }
        if (relatedAssets && activePole) {
            const initialLegendItems = [{ asset_name: (activePole.asset_name ?? activePole.asset_archetype_name)?.toUpperCase(), asset_type_name: activePole.asset_type_name }];
            const newLegendItems = relatedAssets.map((element) => ({
                asset_name: (element.asset_name ?? element.asset_archetype_name)?.toUpperCase(),
                asset_type_name: element.asset_type_name,
            }));

            const combinedLegendItems = [...initialLegendItems];

            newLegendItems.forEach((element) => {
                if (!assetInList(element, combinedLegendItems)) {
                    combinedLegendItems.push(element);
                }
            });

            combinedLegendItems.sort((a, b) => {
                if (a.asset_name !== b.asset_name) {
                    return a.asset_name?.localeCompare(b.asset_name);
                }
                return a.asset_type_name?.localeCompare(b.asset_type_name);
            });

            setLegendItems(combinedLegendItems);
        }
    }, [relatedAssets, activePole, colorCode]);

    const toggleColorCoding = (color = 0xff0000) => {
        if (colorCode) {
            for (let key in measurementMap) {
                if (measurementMap[key].changeColor) {
                    measurementMap[key].changeColor(color);
                }
            }
            setColorCode(false);
        }
        else {
            for (let key in measurementMap) {
                if (measurementMap[key].changeColor) {
                    measurementMap[key].changeColor();
                }
            }
            setColorCode(true);
        }
    };


    return (
        <Box
            sx={{
                backgroundColor: hideLegend ? "rgba(0,0,0,0)" : "rgba(125,125,125,0.7)",
                fontSize: "0.75rem",
                fontWeight: "bold",
                marginLeft: "10px",
                marginTop: "10px",
                padding: "5px",
                borderRadius: "5px",
                overflowY: "auto",
                border: hideLegend ? "none" : "1px, solid, white",
            }}
        >
            {hideLegend ? (
                <Button variant="contained" color="primary" onClick={() => setHideLegend(false)}>
                    Show Legend
                </Button>
            ) : (
                <>
                    {legendItems.map((asset) => {
                        const color = getAssetTypeColor(asset);
                        return (
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "5px",

                                }}
                                key={`${asset.asset_name} - ${asset.asset_type_name}`}
                            >
                                <Circle
                                    style={{ marginRight: "5px" }}
                                    htmlColor={`#${color.toString(16)}`}
                                />
                                <p style={{ margin: 0 }}>{`${asset.asset_name} - ${asset.asset_type_name}`}</p>
                            </div>
                        );
                    })}
                    <div style={{
                        paddingTop: "5px"
                    }}>
                        <Button variant="contained" color="primary" onClick={() => setHideLegend(true)}>
                            Hide Legend
                        </Button>
                    </div>
                    <div style={{
                        paddingTop: "5px"
                    }}>
                        <Button variant="contained" color="primary" onClick={() => toggleColorCoding()}>
                            {(colorCode ? "Remove" : "Use") + " color coding"}
                        </Button>
                    </div>
                </>
            )}
        </Box>
    );
};

export default PointcloudLegend;