/* eslint-disable */
import React, { useState, useRef, useEffect, useContext } from "react";
import PointcloudLegend from '../../custom-components/PointcloudLegend'
import { StoreContext } from '../../Contexts';
import {
  Button
} from '@mui/material';
import { useApis } from "../../Contexts/ApiServiceContext";

const PotreeViewer = () => {
  const { pointCloudApi } = useApis();
  const appContext = useContext(StoreContext);
  const [potreeViewer, setPotreeViewer] = appContext.potreeViewer
  const [activePointcloud, setActivePointcloud] = appContext.activePointcloud;
  const viewerElemRef = useRef();
  const [adjacentTiles, setAdjacentTiles] = useState(null);
  const [activeAttributeName, setActiveAttributeName] = useState("classification"); // used when loading adjacent/new pointclouds to keep viewing consistent
  const [customClassifications, setCustomClassifications] = useState(null);

  const customClassificationSchemes =
  {
    "wireline":
    {
      65: { visible: true, name: 'guy wire', color: [0.6, 0.6, 0.6, 1.0] }, //stays: Guy wires
      14: { visible: true, name: 'wire - conductor (phase)', color: [0.5, 0.12, 0.9, 1.0] }, //cond: Conductors and cables between poles, including comms cables
      15: { visible: true, name: 'structure - transmission tower, pole', color: [0.78, 0.60, .17, 1.0] },// structure: Distribution poles including cross-arms, transformers, insulators, etc
      //75: { visible: true, name: 'structure non-power - pole-like structures that are not connected by wires', color: [1.0, 1.0, 0.0, 1.0] }, //Pole non-power - pole-like structures that are not connected by wires, including street lights, flag poles, etc	
      DEFAULT: { visible: false, name: 'other', color: [0.0, 0.0, 0.0, 1.0] },
    },
    "no_vegetation":
    {
      // Lidar-standard vegetation codes
      3: { visible: false, name: 'Low Vegetation', color: [0.1, 0.7, 0.1, 1.0] }, // 'color' attribute is required. 
      4: { visible: false, name: 'Medium Vegetation', color: [0.2, 0.7, 0.2, 1.0] },
      5: { visible: false, name: 'High Vegetation', color: [0.3, 0.7, 0.3, 1.0] },

      DEFAULT: { visible: true, name: 'other', color: [0.6, 0.6, 0.6, 1.0] },
    },
  };

  const getAdjacentTiles = async () => {
    if (activePointcloud) {
      const theTiles = await pointCloudApi.fetchAdjacentPointcloudTiles(activePointcloud.pointcloud_id, 6);
      setAdjacentTiles(theTiles);
    }
  }

  const displayHandler = (activeAttributeName, customClassificationScheme = null) => {
    setActiveAttributeName(activeAttributeName);
    setCustomClassifications(customClassificationScheme);
    potreeViewer.scene.pointclouds.forEach(pc => pc.material.activeAttributeName = activeAttributeName);
    potreeViewer.setClassifications(customClassificationScheme ? customClassificationScheme : Potree.ClassificationScheme.DEFAULT);
  }

  useEffect(() => {
    const Potree = window.Potree;
    const viewerElem = viewerElemRef.current;
    let newViewer = new Potree.Viewer(viewerElem);
    setPotreeViewer(newViewer);
  }, []);

  useEffect(() => {
    getAdjacentTiles();
    if (activePointcloud) {
      setActivePointcloud(activePointcloud);
      if (potreeViewer !== null) {
        const viewer = potreeViewer;
        viewer.lengthUnit = activePointcloud.is_metric == 0 ? { code: 'ft', unitspermeter: 3.28084 } : { code: 'm', unitspermeter: 1.0 };
        setPotreeViewer(viewer);
      }
    }
  }, [activePointcloud]);

  useEffect(() => {
    if (potreeViewer !== null) {
      const viewer = potreeViewer;
      const Potree = window.Potree;
      viewer.setEDLEnabled(true);
      viewer.setFOV(60);
      viewer.setPointBudget(2 * 1000 * 1000);
      viewer.setClipTask(Potree.ClipTask.SHOW_INSIDE);
      viewer.loadSettingsFromURL();
    }
  }, [potreeViewer]);

  useEffect(() => {
    const Potree = window.Potree;
    const viewer = potreeViewer;
    if (activePointcloud && adjacentTiles) {
      adjacentTiles?.urls.forEach((url) => {
        Potree.loadPointCloud(url).then(e => {
          viewer.scene.addPointCloud(e.pointcloud);
          viewer.fitToScreen();
          viewer.setControls(viewer.earthControls);
          viewer.compass.setVisible(true);
          let material = e.pointcloud.material;
          material.size = .25;
          material.pointSizeType = Potree.PointSizeType.FIXED;
          material.shape = Potree.PointShape.CIRCLE;
          if (activePointcloud.classified) {
            if (customClassifications) {
              viewer.setClassifications(customClassifications);
            } else {
              viewer.setClassifications(Potree.ClassificationScheme.DEFAULT);
            }
            material.activeAttributeName = activeAttributeName;
          }

        }, e => console.error("ERROR: ", e));
      });
    } else {
      getAdjacentTiles();
    }
  }, [adjacentTiles, activePointcloud])

  return (
    <div id="potree-root">
      <div className="potree-wrapper">
        <div id="potree_render_area" ref={viewerElemRef} />
        <div style={{ display: 'flex', justifyContent: 'left', position: 'absolute', top: 0, zIndex: '1' }}>
          <PointcloudLegend />
        </div>
        {activePointcloud?.classified &&
          <div style={{ display: 'flex', justifyContent: 'center', position: 'absolute', bottom: 0, width: '100%', zIndex: '1' }}>
            <Button variant="contained" color="primary" style={{ margin: '0 10px' }} onClick={() => displayHandler("classification")}>Classified</Button>
            <Button variant="contained" color="primary" style={{ margin: '0 10px' }} onClick={() => displayHandler("rgba")}>RGBA</Button>
            <Button variant="contained" color="primary" style={{ margin: '0 10px' }} onClick={() => displayHandler("classification", customClassificationSchemes["wireline"])}>Wireline</Button>
            <Button variant="contained" color="primary" style={{ margin: '0 10px' }} onClick={() => displayHandler("rgba", customClassificationSchemes["no_vegetation"])}>Remove Vegetation</Button>
          </div>
        }
      </div>
    </div>
  );
};

export default PotreeViewer;