export const AssetTypeColorNamed = {
    "POLE": { // Orange  
        "default": 0xFFA400,
    },
    "EQUIPMENT": { // Gold  
        "default": 0xBBA533,
        2: 0xFFD700,
        3: 0xFF8C29,
        4: 0xFFD880,
    },
    "RISER": { // Yellow  
        "default": 0xFFFF33,
        2: 0xE6FF33,
        3: 0xFFEB33,
        4: 0xFFFF99,
    },
    "CROSSARM": { // Green  
        "default": 0x11DD33,
        2: 0x99FF99,
        3: 0x33FF47,
        4: 0x66FF66,
    },
    "GUYING": { // Aqua  
        "default": 0x33FFAF,
        2: 0x33FFD2,
        3: 0x33FF8C,
        4: 0x66FFD6,
    },
    "DRIP LOOP": { // Cyan  
        "default": 0x33FFF6,
        2: 0x66FFFF,
        3: 0x33FFE0,
        4: 0x99FFFF,
    },
    "SPAN ATTACHMENT POINT": { // Blue  
        "default": 0x3357FF,
        "Primary": 0x3347D9,
        "Secondary": 0x6699FF,
        "Communication": 0x99CCFF,
    },
    "DOWN GUY": { // Purple  
        "default": 0xAF33FF,
        2: 0xBF33FF,
        3: 0x9933FF,
        4: 0xD966FF,
    },
    "SPAN": { // RED  
        "default": 0xFF1111,
        "Primary": 0xFF0000,
        "Secondary": 0xFF7788,
        "Communication": 0xFFBBBB,
    },
    "ANCHOR": { // Brown  
        "default": 0xA67C52,
        2: 0xD2B48C,
        3: 0xA66C49,
        4: 0xD9B599,
    },
    "ASSETS": {
        "All": 0xff0000
    }
};

export const getAssetTypeColor = (asset) => {
    const color = asset.asset_name && AssetTypeColorNamed[asset.asset_name.toUpperCase()]
    ? AssetTypeColorNamed[asset.asset_name.toUpperCase()][asset.asset_type_name] ||
    AssetTypeColorNamed[asset.asset_name.toUpperCase()]["default"]
    : asset.asset_archetype_name && AssetTypeColorNamed[asset.asset_archetype_name.toUpperCase()] 
    ? AssetTypeColorNamed[asset.asset_archetype_name.toUpperCase()][asset.asset_type_name] || 
    AssetTypeColorNamed[asset.asset_archetype_name.toUpperCase()]["default"]
    : 0xffffff;
    return color;
}