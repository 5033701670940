import { Color, Raycaster, Vector2 /*, ArrowHelper */ } from "three";

const Potree = window.Potree;
class LockableMeasure extends Potree.Measure {
  constructor(viewer, color = null) {
    super();

    this.viewer = viewer;

    this.color = new Color(color ?? 0xf1f1f1);
    this.locked = true;

    this.isDragging = false;

    this.emissives = {
      locked: 0x222222,
      unlocked: 0x777777,
    };

    this.viewer.renderer.domElement.addEventListener(
      "click",
      this.onClick.bind(this),
      false
    );
  }

  addMarker(point) {
    super.addMarker(point);
    const sphere = this.spheres[this.spheres.length - 1];
    this.sphereColor = sphere.material.color.getHex(); // necessary to store the shader-manipulated hex value for use in changeColor().

    if (this.locked) {
      sphere.material.emissive.setHex(this.emissives["locked"]);
      let listener = sphere._listeners["drag"][0];
      this.originalDragListener = listener;
      sphere.removeEventListener("drag", listener);
    } else {
      sphere.material.emissive.setHex(this.emissives["unlocked"]);
    }

    // Remove mouseover/mouseleave event listeners to enable marking locked/unlocked spheres via the emissive attribute.
    let listener = sphere._listeners["mouseover"][0];
    sphere.removeEventListener("mouseover", listener);
    listener = sphere._listeners["mouseleave"][0];
    sphere.removeEventListener("mouseleave", listener);
  }

  toggleLocked() {
    if (this.locked) {
      this.unlock();
    } else {
      this.lock();
    }
  }

  unlock() {
    if (this.locked) {
      this.spheres.forEach((sphere) => {
        sphere.material.emissive.setHex(this.emissives["unlocked"]);
        sphere.addEventListener("drag", this.originalDragListener);
        this.locked = false;
      });
    }
  }

  lock() {
    if (!this.locked) {
      this.spheres.forEach((sphere) => {
        sphere.material.emissive.setHex(this.emissives["locked"]);
        sphere.removeEventListener("drag", this.originalDragListener);
        this.locked = true;
      });
    }
  }
  /**
   * 
   * @param {number} newColor (**nullable**) Color to set the spheres to.
   * 
   * When `newColor` is null or not passed, this method sets the color back to the original value. Otherwise sets the color of the spheres to `newColor`
   */
  changeColor(newColor = null) {
    if (newColor) {
      this.spheres.forEach((sphere) => {
        sphere.material.color.setHex(newColor);
      });
    }
    else {
      this.spheres.forEach((sphere) => {
        sphere.material.color.setHex(this.sphereColor);
      });
    }
  }


  onClick(event) {
    // Set up raycasting to detect intersections with spheres, based on camera angle and mouse location.
    let mouse = new Vector2();
    let raycaster = new Raycaster();

    // Calculate mouse position in normalized device coordinates (-1 to +1) for both components.
    let renderer = this.viewer.renderer;

    const rendererBounds = renderer.domElement.getBoundingClientRect();
    mouse.x =
      ((event.clientX - rendererBounds.x) / rendererBounds.width) * 2 - 1;
    mouse.y =
      -((event.clientY - rendererBounds.y) / rendererBounds.height) * 2 + 1;

    raycaster.setFromCamera(mouse, this.viewer.scene.getActiveCamera());

    // Debug: Show the ray
    //this.viewer.scene.scene.add(new ArrowHelper( raycaster.ray.direction, raycaster.ray.origin, 1000, Math.random() * 0xffffff ));

    const intersects = raycaster.intersectObjects(this.spheres);

    if (intersects.length > 0) {
      this.toggleLocked();
    } else {
      this.lock();
    }
  }
}

export { LockableMeasure };
